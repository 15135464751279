export type Theme = 'light' | 'dark' | 'contrast'

export interface ThemeObject {
	[key: string]: Record<Theme, any>
}

export interface Member {
	name: string
	email: string
}

export interface ContactRecordObject {
	ContactName: string
	CompanyName: string
	Email: string
	Picture: {
		MimeType: string
		Buffer64: string
	}
}

export interface AddinPageObject {
	maxRows: number
	[id: string]: number
}

export enum Initialization {
	INPROGRESS,
	SUCCESS,
	FAILURE
}

export interface GraphListConversationMembersResponse {
	'@odata.context': string
	value: GraphConversationMember[]
}

export interface GraphConversationMember {
	'@odata.type': string
	id: string
	roles: string[]
	displayName: string
	userId: string
	email: string
}
