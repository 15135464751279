import React, { VFC } from 'react'

interface ErrorIconProps extends React.DetailedHTMLProps<React.SVGAttributes<SVGSVGElement>, SVGSVGElement> {}

const ErrorIcon: VFC<ErrorIconProps> = (props) => {
	return (
		<svg {...props} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
			<path d='M16.143 2l5.857 5.858v8.284l-5.857 5.858h-8.286l-5.857-5.858v-8.284l5.857-5.858h8.286zm.828-2h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-6.471 6h3l-1 8h-1l-1-8zm1.5 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z' />
		</svg>
	)
}

export default ErrorIcon
