import { IContext } from 'msteams-ui-components-react'
import { createThemeObject } from 'utils'

const getThemeStyles = (context: IContext) => {
	const { light, dark, highContrast: contrast } = context.colors
	return createThemeObject(context, {
		wrapper: {
			light: {
				color: light.brand00,
				borderBottom: `${light.gray10} 1px solid`
			},
			dark: {
				color: dark.brand00
			},
			contrast: {
				color: contrast.yellow,
				borderBottom: `${contrast.white} 1px solid`
			}
		}
	})
}

export default getThemeStyles
