import { IContext } from 'msteams-ui-components-react'
import { createThemeObject } from 'utils'

const getThemeStyles = (context: IContext) => {
	const { light, dark, highContrast: contrast } = context.colors
	return createThemeObject(context, {
		number: {
			light: {
				color: light.brand00
			},
			dark: {
				color: dark.brand00
			},
			contrast: {
				color: contrast.yellow
			}
		},
		wrapper: {
			light: {
				color: light.black,
				backgroundColor: light.white,
				borderBottom: `${light.gray10} 1px solid`
			},
			dark: {
				color: dark.white,
				backgroundColor: dark.black,
				borderBottom: `${dark.gray10} 1px solid`
			},
			contrast: {
				color: contrast.white,
				backgroundColor: contrast.black,
				borderBottom: `${contrast.white} 1px solid`
			}
		}
	})
}

export default getThemeStyles
