import Axios, { AxiosResponse } from 'axios'
import MockIntegrator from './mock'
import {
	AddressBooksReponse,
	DoActionBody,
	IntegratorInterface,
	MakeCallBody,
	MakeCallReponse,
	RegisterBody,
	RegisterResponse,
	SearchBody,
	SearchPeersResponse,
	SearchResponse,
	ShowWindowBody,
	VersionResponse
} from './types'

interface Config {
	/** Whether the API calls should be over HTTPS */
	https: boolean
	/** Name of the application to be registered */
	name: string
	/** A GUID used to identify the application */
	id: string
}

interface Headers {
	'Content-Type': string
	ApplicationId: string
}

class Integrator implements IntegratorInterface {
	private static version = 'v1'
	private static appId = '43438b65-3d83-4f1b-ad2f-3d39aab7306e'
	private isInitialized = false
	private config = {
		https: true,
		name: 'Go Integrator Search and Call (Teams Application)',
		id: Integrator.appId
	}
	private headers: Headers

	constructor(config?: Config) {
		this.config = Object.assign({}, this.config, config)
		this.headers = {
			'Content-Type': 'application/x-www-form-urlencoded',
			ApplicationId: this.config.id
		}
	}

	public async init(): Promise<boolean> {
		const register = async () => {
			await this.register({ Name: this.config.name, Id: this.config.id }, false)
		}
		try {
			await this.version(false)
			this.isInitialized = true
		} catch (e: any) {
			if (e.message !== 'Network Error') {
				await register()
				this.isInitialized = true
			}
		}
		return this.isInitialized
	}

	private getAPIEndpoint(route: string): string {
		const origin = this.config.https ? 'https://localhost:10443' : 'http://localhost:10080'
		return `${origin}/api/${Integrator.version}/${route}`
	}

	private post<T>(endpoint: string, body: any, requiresInitialization: boolean = true): Promise<AxiosResponse<T>> {
		if (requiresInitialization && !this.isInitialized) {
			throw new Error('Integrator has not initialized')
		}
		const urlencoded = new URLSearchParams()
		Object.entries(body).forEach(([k, v]) => urlencoded.append(k, v.toString()))

		return Axios.post(this.getAPIEndpoint(endpoint), urlencoded, { headers: this.headers })
	}

	private get<T>(endpoint: string, requiresInitialization: boolean = true): Promise<AxiosResponse<T>> {
		if (requiresInitialization && !this.isInitialized) {
			throw new Error('Integrator has not initialized')
		}
		return Axios.get(this.getAPIEndpoint(endpoint), { headers: this.headers })
	}

	public register(body: RegisterBody, requiresInitialization: boolean = true): Promise<AxiosResponse<RegisterResponse>> {
		return this.post<RegisterResponse>('Register', body, requiresInitialization)
	}

	public search(body: SearchBody): Promise<AxiosResponse<SearchResponse>> {
		return this.post<SearchResponse>('Search', body)
	}

	public searchPeers(body: SearchBody): Promise<AxiosResponse<SearchPeersResponse>> {
		return this.post<SearchPeersResponse>('SearchPeers', body)
	}

	public addressBooks(): Promise<AxiosResponse<AddressBooksReponse>> {
		return this.get<AddressBooksReponse>('AddressBooks')
	}

	public makeCall(body: MakeCallBody): Promise<AxiosResponse<MakeCallReponse>> {
		return this.post<MakeCallReponse>('MakeCall', body)
	}

	public showWindow(body: ShowWindowBody): Promise<AxiosResponse<void>> {
		return this.post<void>('ShowWindow', body)
	}

	public doAction(body: DoActionBody): Promise<AxiosResponse<void>> {
		return this.post<void>('DoAction', body)
	}

	public version(requiresInitialization: boolean = true): Promise<AxiosResponse<VersionResponse>> {
		return this.get<VersionResponse>('Version', requiresInitialization)
	}
}

export default process.env.REACT_APP_USE_MOCK_SERVICES === 'true' ? MockIntegrator : Integrator
