import { connectTeamsComponent, ITeamsThemeContextProps } from 'msteams-ui-components-react'
import { FC, useEffect, useState } from 'react'
import { PageProps } from '..'
import { Card, Contact, NoResults, LoadingPage } from 'components'
import type { ContactRecord, IntegratorInterface, SearchResponse } from 'Integrator/types'
import type { GraphConversationMember, GraphListConversationMembersResponse } from 'types'

const searchUsersContacts = async (integrator: IntegratorInterface, members: GraphConversationMember[]): Promise<SearchResponse[]> => {
	const searches = []
	for (let i = 0; i < members.length; i++) {
		const member = members[i]
		if (member.displayName) {
			const search = integrator.search({ query: member.displayName }).then((res) => res.data)
			searches.push(search)
		}
	}
	return Promise.all(searches)
}

const Call: FC<PageProps & ITeamsThemeContextProps> = ({ context, integrator, parameters }) => {
	const [contacts, setContacts] = useState<ContactRecord[]>(null)
	const [error, setError] = useState<string>(null)

	useEffect(() => {
		const init = async () => {
			const { fromName, graphEndpoint } = parameters

			const res = await fetch(decodeURIComponent(graphEndpoint), {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			})

			if (res.status === 401) {
				localStorage.removeItem('token')
				window.location.reload()
			} else if (res.status === 400) {
				setError('An error has occured: 400 - Bad Request\n(Known issue)')
			}

			const members = ((await res.json()) as GraphListConversationMembersResponse).value.filter(
				(member) => member.displayName !== decodeURIComponent(fromName)
			)

			const results = await searchUsersContacts(integrator, members)

			const contacts = []
			for (let i = 0; i < results.length; i++) {
				const result = results[i]
				const records = result.flatMap<ContactRecord>((addin) => addin.Records)
				if (records.length === 0) {
					continue
				} else if (records.length === 1) {
					contacts.push(records[0])
				} else {
					const { email } = members[i]
					if (email) {
						const closest = records.filter((record) => record.Fields.find((field) => field.Name === 'Email').Value === email)[0]
						contacts.push(closest)
					} else {
						contacts.push(records[0])
					}
				}
			}
			setContacts(contacts)
		}
		if (integrator) {
			init()
		}
	}, [parameters, integrator])

	if (error) {
		return <Card icon='error' title={error} />
	}
	return contacts !== null ? (
		<div className='box-border p-4 h-screen w-screen flex justify-center items-center'>
			{
				contacts.length === 0 ? (
					<NoResults context={context} />
				) : contacts.length === 1 ? (
					<Contact integrator={integrator} contact={contacts[0]} />
				) : null
				// <ContactList />
			}
		</div>
	) : (
		<LoadingPage />
	)
}

export default connectTeamsComponent(Call)
