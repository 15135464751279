import { FC, useState } from 'react'
import { connectTeamsComponent, Dropdown, ITeamsThemeContextProps, PrimaryButton } from 'msteams-ui-components-react'
import CallIcon from './CallIcon'

import type { ContactRecordObject } from 'types'
import type { ContactRecord, IntegratorInterface } from 'Integrator/types'

import Initials from '../Initials'
import getThemeStyles from './themes'

interface ContactProps {
	integrator: IntegratorInterface
	contact: ContactRecord
}

const Contact: FC<ContactProps & ITeamsThemeContextProps> = ({ integrator, contact, context }) => {
	const [selectedNumberIndex, setSelectedNumberIndex] = useState<number>(0)

	const contactObject: ContactRecordObject = contact.Fields.reduce((acc, value) => {
		acc[value.Name.replace(/ /g, '') as keyof ContactRecordObject] = value.Value
		return acc
	}, {} as ContactRecordObject)

	const call = async (number: string): Promise<void> => {
		try {
			await integrator.makeCall({ destination: number })
		} catch (err) {
			console.log(err)
		}
	}

	const numberFields = contact.Fields.filter(({ Type }) => Type === 'Phone')
	const numbers = numberFields
		.filter((field) => field.Value !== '')
		.map((field, i) => ({
			text: field.Value,
			onClick: () => setSelectedNumberIndex(i)
		}))

	const themes = getThemeStyles(context)

	return (
		<div
			className='flex flex-col justify-center items-center h-screen w-[calc(100vw-3rem)] mx-6 my-2'
			style={themes.wrapper}
			key={contact.UniqueId}
		>
			<Initials name={contact.DisplayText || contactObject.ContactName} />
			<div className='text-lg font-bold mb-2'>{contactObject.ContactName}</div>
			<div className='mb-2'>
				{numbers.length > 1 ? <Dropdown mainButtonText={numberFields[selectedNumberIndex].Value} items={numbers} /> : numbers[0].text}
			</div>
			<div className='mb-2'>{contactObject.Email}</div>
			<PrimaryButton className='flex items-center justify-center' onClick={() => call(numberFields[selectedNumberIndex].Value)}>
				<CallIcon style={themes.icon} className='pr-1' />
				<span>Call</span>
			</PrimaryButton>
		</div>
	)
}

export default connectTeamsComponent(Contact)
