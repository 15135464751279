import { connectTeamsComponent, ITeamsThemeContextProps } from 'msteams-ui-components-react'
import React, { FC } from 'react'
import ErrorIcon from './ErrorIcon'

import InfoIcon from './InfoIcon'
import getThemeStyles from './themes'

type IconType = 'error' | 'info'
interface CardProps {
	title: React.ReactNode
	icon?: IconType
}

const Card: FC<CardProps & ITeamsThemeContextProps> = ({ context, title, icon, children }) => {
	const themes = getThemeStyles(context)

	const getIcon = (icon: IconType) => {
		switch (icon) {
			case 'error':
				return <ErrorIcon className='mr-2' style={themes.errorIcon} />
			case 'info':
				return <InfoIcon className='mr-2' style={themes.infoIcon} />
			default:
				return null
		}
	}

	return (
		<div style={themes.wrapper} className='flex w-screen h-screen items-center justify-center'>
			<div style={themes.card} className='w-[30rem] flex flex-col justify-center p-4 box-border'>
				<span className='self-center m-4 flex items-center font-bold'>
					{getIcon(icon)}
					{title}
				</span>
				<div className='mx-4'>{children}</div>
			</div>
		</div>
	)
}

export default connectTeamsComponent(Card)
