import { IContext } from 'msteams-ui-components-react'
import { createThemeObject } from 'utils'

const getThemeStyles = (context: IContext) => {
	const { light, dark, highContrast: contrast } = context.colors
	return createThemeObject(context, {
		wrapper: {
			light: {
				color: light.black,
				backgroundColor: light.white
			},
			dark: {
				color: dark.white,
				backgroundColor: dark.gray10,
				border: `${dark.gray10} 2px solid`
			},
			contrast: {
				color: contrast.white,
				backgroundColor: contrast.black,
				border: `${contrast.white} 2px solid`
			}
		}
	})
}

export default getThemeStyles
