import { IContext } from 'msteams-ui-components-react'
import { FC } from 'react'
import { Initials } from 'components'
import getThemeStyles from './themes'

import type { ContactRecord, IntegratorInterface } from 'Integrator/types'
import type { ContactRecordObject } from 'types'

interface ContactRecordProps {
	contact: ContactRecord
	context: IContext
	integrator: IntegratorInterface
}

const ContactRecordComponent: FC<ContactRecordProps> = ({ contact, context, integrator }) => {
	const contactObject: ContactRecordObject = contact.Fields.reduce((acc, value) => {
		acc[value.WellKnownName as keyof ContactRecordObject] = value.Value
		return acc
	}, {} as ContactRecordObject)

	const call = async (number: string): Promise<void> => {
		try {
			await integrator.makeCall({ destination: number })
		} catch (err) {
			console.log(err)
		}
	}

	const themes = getThemeStyles(context)

	// Todo: trim name or number when too long, use title attribute on element for full value
	const name = contact.DisplayText || '-'

	return (
		<div style={themes.wrapper} className='flex items-center p-2 flex-1 relative'>
			<div className='flex mr-2'>
				{contactObject.Picture ? (
					<img
						className='text-sm flex justify-center items-center h-8 min-w-[2rem] rounded-full'
						src={`data:${contactObject.Picture.MimeType};base64, ${contactObject.Picture.Buffer64}`}
						alt={contact.DisplayText}
					/>
				) : (
					<Initials name={contactObject.ContactName || contactObject.CompanyName} />
				)}
			</div>
			<div className='basis-96 truncate'>{name}</div>
			<div className='basis-32 mx-4 text-right truncate'>
				{contact.Fields.filter(({ Type }) => Type === 'Phone').map((field) => (
					<div onClick={() => call(field.Value)} className='cursor-pointer truncate' style={themes.number} key={field.DescriptorId}>
						{field.Value}
					</div>
				))}
			</div>
			<div>{contact.RecordType}</div>
			<div className='flex flex-1 basis-32 items-end justify-end'>
				{contact.Actions.map((action) =>
					action.Icon ? (
						<img
							key={action.UniqueId}
							onClick={() => integrator.doAction({ actionId: action.UniqueId })}
							className='cursor-pointer ml-2 text-sm flex justify-center items-center h-6 min-w-[1.5rem] '
							src={`data:${action.Icon.MimeType};base64, ${action.Icon.Buffer64}`}
							title={action.DisplayText}
							alt={action.DisplayText}
						/>
					) : null
				)}
			</div>
		</div>
	)
}

export default ContactRecordComponent
