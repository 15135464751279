import { IContext, ThemeStyle } from 'msteams-ui-components-react'
import { Theme, ThemeObject } from './types'

const joinClassNames = (...classNames: string[]): string => {
	let className = ''
	for (let i = 0; i < classNames.length; i++) {
		if (classNames[i]) className += ` ${classNames[i]}`
	}
	return className
}

const getTheme = (style: ThemeStyle): Theme => {
	switch (style) {
		case ThemeStyle.Dark:
			return 'dark'
		case ThemeStyle.HighContrast:
			return 'contrast'
		case ThemeStyle.Light:
		default:
			return 'light'
	}
}

// The purpose of this function is to allow modularisation of theme styles
const createThemeObject = (context: IContext, themes: ThemeObject) => {
	const theme = getTheme(context.style)
	return Object.entries(themes).reduce((acc, [key, value]) => {
		acc[key] = value[theme]
		return acc
	}, {} as any)
}

export { joinClassNames, getTheme, createThemeObject }
