import { ITeamsThemeContextProps } from 'msteams-ui-components-react'
import { VFC } from 'react'
import getThemeStyles from './themes'

interface NoResultsProps {
	context: ITeamsThemeContextProps['context']
}

const NoResults: VFC<NoResultsProps> = ({ context }) => {
	const themes = getThemeStyles(context)
	return (
		<div className='m-2' style={themes.wrapper}>
			Couldn't find any matching contacts.
		</div>
	)
}

export default NoResults
