import { FC } from 'react'
import { connectTeamsComponent, ITeamsThemeContextProps } from 'msteams-ui-components-react'
import AddinCollectionComponent from './AddinCollection'
import type { AddinCollection, AddressBooksObject, IntegratorInterface } from 'Integrator/types'

import getThemeStyles from './themes'

interface AddinResultsProps {
	maxRows: number
	integrator: IntegratorInterface
	addinCollections: AddinCollection[]
	addressBooksObject: AddressBooksObject
	query: string
	loadMore(addinId: string): Promise<void>
}

const AddinResults: FC<AddinResultsProps & ITeamsThemeContextProps> = ({
	maxRows,
	loadMore,
	addinCollections,
	addressBooksObject,
	context,
	integrator,
	query
}) => {
	const themes = getThemeStyles(context)
	return (
		<div style={themes.wrapper} className='flex flex-col overflow-hidden rounded-sm'>
			<div className='h-full overflow-scroll'>
				{addinCollections
					? addinCollections.map((addinCollection) =>
							addinCollection.Records.length > 0 ? (
								<AddinCollectionComponent
									query={query}
									maxRows={maxRows}
									loadMore={loadMore}
									context={context}
									integrator={integrator}
									key={addinCollection.AddinId}
									addinName={addressBooksObject[addinCollection.AddinId].Name}
									addinCollection={addinCollection}
								/>
							) : null
					  )
					: null}
			</div>
		</div>
	)
}

export default connectTeamsComponent(AddinResults)
