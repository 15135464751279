import { connectTeamsComponent, ITeamsThemeContextProps, PrimaryButton } from 'msteams-ui-components-react'
import { FC, VFC } from 'react'
import Card from '../Card'

import getThemeStyles from './themes'

interface LinkProps {
	href: string
}

const Uninitialized: VFC<ITeamsThemeContextProps> = ({ context }) => {
	const themes = getThemeStyles(context)
	const Link: FC<LinkProps> = (props) => (
		<a style={themes.link} target='_blank' rel='noreferrer' href={props.href}>
			{props.children}
		</a>
	)
	const title = 'We failed to create a connection to Go Integrator.'
	return (
		<Card icon='error' title={title}>
			<span>There are a couple of checks to carry out:</span>
			<ul className='space-y-2 list-outside list-disc pl-6 mt-2'>
				<li>
					Please ensure the Go Integrator desktop client is installed and running. If you have not installed Go Integrator, this is
					available to download from <Link href='https://mondago.com/searchand-call-msteams-gointegrator/#download'>here</Link>.
				</li>
				<li>
					Ensure you are running the application on the Teams desktop client. This application will not work on any mobile Teams client.
				</li>
			</ul>

			<div className='mt-2'>
				For further information and frequently asked questions on Go Integrator Search and Call, please click{' '}
				<Link href='https://mondago.com/searchand-call-msteams-gointegrator/'>here</Link>.
			</div>
			<div className='pt-2 flex w-full'>
				<PrimaryButton className='ml-auto' onClick={() => window.location.reload()}>
					Retry
				</PrimaryButton>
			</div>
		</Card>
	)
}

export default connectTeamsComponent(Uninitialized)
