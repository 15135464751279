import { IContext } from 'msteams-ui-components-react'
import { createThemeObject } from 'utils'

const getThemeStyles = (context: IContext) => {
	const { light, dark, highContrast: contrast } = context.colors
	return createThemeObject(context, {
		wrapper: {
			light: {
				backgroundColor: light.white,
				color: light.black
			},
			dark: {
				backgroundColor: dark.gray10,
				color: dark.white
			},
			contrast: {
				color: contrast.white
			}
		},
		icon: {
			light: {
				fill: light.white
			},
			dark: {
				fill: dark.black
			},
			contrast: {
				fill: contrast.white
			}
		}
	})
}

export default getThemeStyles
