import { IContext } from 'msteams-ui-components-react'
import { createThemeObject } from 'utils'

const getThemeStyles = (context: IContext) => {
	const { light, dark, highContrast: contrast } = context.colors
	return createThemeObject(context, {
		wrapper: {
			light: {
				backgroundColor: light.gray10
			},
			dark: {
				backgroundColor: dark.black
			},
			contrast: {
				backgroundColor: contrast.black
			}
		},
		card: {
			light: {
				backgroundColor: light.white
			},
			dark: {
				backgroundColor: dark.gray10,
				color: dark.white
			},
			contrast: {
				color: contrast.white,
				backgroundColor: contrast.black,
				border: `2px solid ${contrast.white}`
			}
		},
		errorIcon: {
			light: { fill: light.red },
			dark: { fill: dark.red },
			contrast: { fill: contrast.white }
		},
		infoIcon: {
			light: { fill: light.brand00 },
			dark: { fill: dark.orange },
			contrast: { fill: contrast.white }
		}
	})
}

export default getThemeStyles
