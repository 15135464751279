import { FC, useEffect, useState } from 'react'
import { connectTeamsComponent, Input, ITeamsThemeContextProps, PrimaryButton } from 'msteams-ui-components-react'
import { Row, SettingsIcon } from 'components'
import { AddinResults, Loading } from 'components'

import type { Initialization } from 'types'
import type { AddressBooksObject, IntegratorInterface, AddinCollection, ContactRecord, ContactRecordField, SearchResponse } from 'Integrator/types'

import getThemeStyles from './themes'

interface SearchProps {
	integrator: IntegratorInterface
	integratorInitialization: Initialization
}

const createField = (name: string, type: string, value: string): ContactRecordField => ({
	DescriptorId: type,
	Name: name,
	Ordinal: 0,
	Type: type,
	Value: value,
	WellKnownName: name
})

const count = 5
const Search: FC<SearchProps & ITeamsThemeContextProps> = ({ integrator, context }) => {
	const [isSearching, setIsSearching] = useState<boolean>(false)
	const [searchText, setSearchText] = useState<string>('')
	const [addressBooksObject, setAddressBooksObject] = useState<AddressBooksObject>(null)
	const [addinCollections, setAddinCollections] = useState<AddinCollection[]>(null)

	const searchContacts = async (query: string, offset: number): Promise<SearchResponse> => {
		return (await integrator.search({ query, count, offset, includePictures: true })).data
	}

	const searchPeers = async (query: string, offset: number): Promise<AddinCollection> => {
		const peers = (await integrator.searchPeers({ query, count, offset, includePictures: true })).data
		return {
			AddinId: 'Users',
			Records: peers.map<ContactRecord>((peerRecord) => ({
				Actions: [],
				RecordType: null,
				DisplayText: peerRecord.Contact.DisplayName,
				Fields: [
					createField('Id', 'Id', peerRecord.Contact.Id),
					createField('ContactName', 'Text', peerRecord.Contact.DisplayName),
					createField('Phone', 'Phone', peerRecord.Contact.DisplayTel),
					...(peerRecord.EmailAddress ? [] : [createField('Email', 'Text', peerRecord.EmailAddress)])
				],
				UniqueId: peerRecord.Id
			})),
			ExceptionText: null
		}
	}

	const loadMore = async (addinId: string) => {
		const query = searchText.trim()
		const contactsLength = addinCollections.find((addin) => addin.AddinId === addinId).Records.length
		if (addinId === 'Users') {
			const searchPeersResults = await searchPeers(query, contactsLength)
			setAddinCollections((old) => {
				const index = old.findIndex((addin) => addin.AddinId === addinId)
				const newCollections = [...old]
				newCollections[index] = { ...newCollections[index], Records: [...old[index].Records, ...searchPeersResults.Records] }
				return newCollections
			})
		} else {
			const searchResults = await searchContacts(query, contactsLength)
			// TODO: Cache response
			// although limiting, this is the only way to get a particular addin's "load more" functionality
			const newRecords = searchResults.find((addin) => addin.AddinId === addinId).Records
			setAddinCollections((old) => {
				const index = old.findIndex((addin) => addin.AddinId === addinId)
				const newCollections = [...old]
				newCollections[index] = { ...newCollections[index], Records: [...old[index].Records, ...newRecords] }
				return newCollections
			})
		}
	}

	const search = async () => {
		setIsSearching(true)
		try {
			const query = searchText.trim()
			const searchResults = await searchContacts(query, 0)
			const usersAddinCollection = await searchPeers(query, 0)

			const data = [...searchResults, usersAddinCollection]
			setAddinCollections(data)
		} catch (e) {
			console.log(e)
		}
		setIsSearching(false)
	}

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			search()
		}
	}

	const openConfigWindow = () => {
		integrator.showWindow({ window: 'configuration' })
	}

	useEffect(() => {
		const init = async () => {
			const addressBooks = (await integrator.addressBooks()).data
			const addressBooksObject = addressBooks.reduce(
				(a, addressBook) => {
					a[addressBook.AddinId] = addressBook
					return a
				},
				{ Users: { Name: 'Users', AddinId: 'Users' } } as AddressBooksObject
			)

			console.log(addressBooksObject)
			setAddressBooksObject(addressBooksObject)
		}

		if (integrator) {
			init()
		}
	}, [integrator])

	const themes = getThemeStyles(context)

	return (
		<div className='box-border h-screen flex flex-col space-y-4'>
			<Row style={themes.searchBar} className='justify-between rounded-sm p-2'>
				<Row>
					<Input
						onKeyDown={handleInputKeyDown}
						placeholder='Search for contacts'
						className='w-[31rem] pr-4'
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
					/>
					<PrimaryButton className='flex items-center justify-center' disabled={searchText === ''} onClick={search}>
						{isSearching ? <Loading size={1} /> : 'Search'}
					</PrimaryButton>
				</Row>

				<PrimaryButton style={themes.configButton} className='flex justify-center items-center min-w-[1rem] w-16' onClick={openConfigWindow}>
					<SettingsIcon />
				</PrimaryButton>
			</Row>
			<AddinResults
				query={searchText}
				maxRows={count}
				loadMore={loadMore}
				integrator={integrator}
				addinCollections={addinCollections}
				addressBooksObject={addressBooksObject}
			/>
		</div>
	)
}

export default connectTeamsComponent(Search)
