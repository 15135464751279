import { ITeamsThemeContextProps } from 'msteams-ui-components-react'
import React, { FC } from 'react'
import getThemeStyles from './themes'

interface LoadMoreProps {
	onClick: React.DOMAttributes<HTMLDivElement>['onClick']
	context: ITeamsThemeContextProps['context']
}

const LoadMore: FC<LoadMoreProps> = ({ context, onClick }) => {
	const themes = getThemeStyles(context)
	return (
		<div onClick={onClick} style={themes.wrapper} className='p-4 cursor-pointer'>
			More results...
		</div>
	)
}

export default LoadMore
