import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { LoadingPage } from 'components'
import * as microsoftTeams from '@microsoft/teams-js'

interface AuthEndProps {}

const AuthEnd: FC<AuthEndProps> = (props) => {
	const location = useLocation()
	const init = () => {
		microsoftTeams.initialize(() => {
			const hashParams = location.pathname
				.slice(1)
				.split('&')
				.reduce((acc, pair) => {
					const [key, value] = pair.split('=').map(decodeURIComponent)
					acc[key] = value
					return acc
				}, {} as any)
			if (hashParams['error']) {
				microsoftTeams.authentication.notifyFailure(hashParams['error'])
			} else if (hashParams['access_token']) {
				const state = localStorage.getItem('simple.state')
				if (state !== hashParams['state']) {
					microsoftTeams.authentication.notifyFailure('StateDoesNotMatch')
				} else {
					microsoftTeams.authentication.notifySuccess(JSON.stringify(hashParams))
				}
			} else {
				microsoftTeams.authentication.notifyFailure('UnexpectedFailure')
			}
		})
	}

	useEffect(() => {
		init()
	})

	return <LoadingPage />
}

export default AuthEnd
