import { IContext } from 'msteams-ui-components-react'
import { createThemeObject } from 'utils'

const getThemeStyles = (context: IContext) => {
	const { light, dark, highContrast: contrast } = context.colors
	return createThemeObject(context, {
		wrapper: {
			light: {
				color: light.black,
				backgroundColor: light.white
			},
			dark: {
				color: dark.white,
				backgroundColor: dark.gray10
			},
			contrast: {
				color: contrast.yellow,
				backgroundColor: contrast.black
			}
		},
		title: {
			light: {
				backgroundColor: light.gray08,
				borderBottom: `${light.gray10} 1px solid`
			},
			dark: {},
			contrast: {
				borderBottom: `${contrast.white} 2px solid`
			}
		},
		caretOpen: {
			light: {
				fill: light.black
			},
			dark: {
				fill: dark.white
			},
			contrast: {
				fill: contrast.yellow
			}
		},
		caretClosed: {
			light: {
				stroke: light.black,
				fill: 'transparent'
			},
			dark: {
				stroke: dark.white,
				fill: 'transparent'
			},
			contrast: {
				stroke: contrast.yellow,
				fill: contrast.yellow
			}
		}
	})
}

export default getThemeStyles
