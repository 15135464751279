import { FC } from 'react'
import { Route, useLocation } from 'react-router-dom'

import Search from './search'
import Call from './call'
import AuthStart from './auth-start'
import AuthEnd from './auth-end'
import Authenticate from './authenticate'
import { Uninitialized, LoadingPage } from 'components'

import { Initialization } from '../types'
import type { IntegratorInterface } from 'Integrator/types'

export interface PageProps {
	integrator: IntegratorInterface
	integratorInitialization: Initialization
	parameters: { [key: string]: string }
}

const Pages: FC<PageProps> = (props) => {
	// const isAuthenticated = localStorage.getItem('token') !== null
	const isAuthenticated = true
	const location = useLocation()
	if (location.pathname.startsWith('/access_token=')) return <AuthEnd />
	switch (props.integratorInitialization) {
		case Initialization.FAILURE:
			return <Uninitialized />
		case Initialization.INPROGRESS:
			return <LoadingPage />
		case Initialization.SUCCESS:
			return isAuthenticated ? (
				<>
					<Route path='/search' render={() => <Search {...props} />} />
					<Route path='/call' render={() => <Call {...props} />} />
				</>
			) : (
				<>
					{/* We have to use window.location instead of useLocation as it fails here due to the bug in AuthStart */}
					{['/authenticate', '/access_token='].every((string) => !window.location.pathname.startsWith(string)) ? <AuthStart /> : null}
					{window.location.pathname.startsWith('/authenticate') ? <Authenticate /> : null}
				</>
			)
	}
}

export default Pages
