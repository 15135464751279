import { FC } from 'react'

interface InitialsProps {
	name: string
}

const colorLookup = [
	'#CCD999',
	'#B5D2F3',
	'#F3B9DE',
	'#9DDB9D',
	'#8EB8A2',
	'#FF9DD7',
	'#BE9BBB',
	'#AF9DDC',
	'#8E8E8E',
	'#80D5D4',
	'#96C4F7',
	'#95ABCB',
	'#ECA995',
	'#DC8EA3',
	'#F68888'
]

const Initials: FC<InitialsProps> = ({ name }) => {
	const colorFromName = (name: string) => {
		if (name === undefined) return colorLookup[1]
		let hashCode = 0
		for (let i = name.length - 1; i >= 0; i--) {
			const character = name.charCodeAt(i)
			const shift = i % 8
			const c = character << shift
			hashCode = hashCode ^ (c + (character >> (8 - shift)))
		}

		return colorLookup[hashCode % colorLookup.length]
	}

	const getInitials = (name: string) => {
		const initials = name
			.match(/\b[^\W\d]/g)
			.join('')
			.toUpperCase()
			.slice(0, 3)
		return initials
	}

	const initials = name ? getInitials(name) : ''

	return (
		<div style={{ backgroundColor: colorFromName(initials) }} className='text-sm flex justify-center items-center h-8 min-w-[2rem] rounded-full'>
			{initials}
		</div>
	)
}

export default Initials
