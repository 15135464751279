import { VFC } from 'react'

interface LoadingProps {
	size: number
}

const Loading: VFC<LoadingProps> = (props) => {
	const size = (props.size || 2) * 16
	const borderWidth = size / 8

	return (
		<div
			className='animate-spin rounded-full'
			style={{
				width: size,
				height: size,
				borderColor: 'rgba(0, 0, 0, 0.05)',
				border: `${borderWidth}px solid`,
				borderTop: `${borderWidth}px solid transparent`
			}}
		/>
	)
}

export default Loading
