import { IContext } from 'msteams-ui-components-react'
import { createThemeObject } from 'utils'

const getThemeStyles = (context: IContext) => {
	const { light, dark, highContrast: contrast } = context.colors
	return createThemeObject(context, {
		link: {
			light: {
				color: light.brand00
			},
			dark: {
				color: dark.brand00
			},
			contrast: {
				color: contrast.yellow
			}
		}
	})
}

export default getThemeStyles
