import { IContext } from 'msteams-ui-components-react'
import { createThemeObject } from 'utils'

const getThemeStyles = (context: IContext) => {
	const { light, dark, highContrast: contrast } = context.colors
	return createThemeObject(context, {
		searchBar: {
			light: { backgroundColor: light.white },
			dark: { backgroundColor: dark.gray14 },
			contrast: { backgroundColor: contrast.black }
		},
		configButton: {
			light: { fill: light.gray14 },
			dark: { fill: dark.gray14 },
			contrast: { fill: contrast.black }
		}
	})
}

export default getThemeStyles
