import { VFC } from 'react'
import Loading from '../LoadingSpinner'

const LoadingPage: VFC = () => {
	return (
		<div className='flex justify-center items-center h-screen w-screen'>
			<Loading size={2} />
		</div>
	)
}

export default LoadingPage
