import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { TeamsThemeContext, getContext, ThemeStyle } from 'msteams-ui-components-react'
import Integrator from './Integrator'
import { IntegratorInterface } from './Integrator/types'
import { Initialization } from './types'
import Pages from './pages'
import * as microsoftTeams from '@microsoft/teams-js'

import './index.css'

import { HashRouter } from 'react-router-dom'

const App = () => {
	// we do this because we're using a hashrouter, and a qs after a hash is never parsed correctly
	const qs = window.location.href.split('?').pop()
	const parameters = Object.fromEntries(new URLSearchParams(qs).entries())

	const [integrator, setIntegrator] = useState<IntegratorInterface>(null)
	const [integratorInitialization, setIntegratorInitialization] = useState<Initialization>(Initialization.INPROGRESS)
	const [theme, setTheme] = useState<ThemeStyle>(null)

	const updateTheme = (theme: string): void => {
		switch (theme) {
			case 'dark':
				setTheme(ThemeStyle.Dark)
				break
			case 'contrast':
				setTheme(ThemeStyle.HighContrast)
				break
			case 'default':
			default:
				setTheme(ThemeStyle.Light)
		}
	}

	useEffect(() => {
		const init = async () => {
			const integrator = new Integrator()
			const isInitialized = await integrator.init()
			setIntegratorInitialization(isInitialized ? Initialization.SUCCESS : Initialization.FAILURE)
			setIntegrator(integrator)

			const inTeams = (): boolean => {
				try {
					return window.self !== window.top
				} catch (e) {
					return true
				}
			}
			if (inTeams()) {
				microsoftTeams.initialize(() => {
					microsoftTeams.appInitialization.notifyAppLoaded()
					microsoftTeams.appInitialization.notifySuccess()
					microsoftTeams.registerOnThemeChangeHandler(updateTheme)
				})
			}
		}
		init()
	}, [])

	useEffect(() => {
		if (parameters.theme !== undefined) {
			updateTheme(parameters.theme)
		}
	}, [parameters.theme])

	return (
		<TeamsThemeContext.Provider value={getContext({ baseFontSize: 16, style: theme })}>
			<HashRouter>
				<Pages integratorInitialization={integratorInitialization} parameters={parameters} integrator={integrator} />
			</HashRouter>
		</TeamsThemeContext.Provider>
	)
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)

export default App
