import { AxiosResponse } from 'axios'
import {
	AddressBooksReponse,
	ContactRecordField,
	DoActionBody,
	IntegratorInterface,
	MakeCallBody,
	MakeCallReponse,
	RegisterResponse,
	SearchBody,
	SearchPeersResponse,
	SearchResponse,
	ShowWindowBody,
	VersionResponse
} from './types'

class MockIntegrator implements IntegratorInterface {
	public async init(): Promise<boolean> {
		// await new Promise((r) => setTimeout(r, 1000))
		return Promise.resolve(true)
	}

	private createAxiosResponse<T>(body: T): Promise<AxiosResponse<T>> {
		return Promise.resolve({
			config: null,
			data: body,
			headers: null,
			status: 200,
			statusText: null
		})
	}
	public addressBooks(): Promise<AxiosResponse<AddressBooksReponse>> {
		return this.createAxiosResponse<AddressBooksReponse>([
			{
				AddinId: 'test',
				Name: 'Test'
			}
		])
	}

	public makeCall(body: MakeCallBody): Promise<AxiosResponse<MakeCallReponse>> {
		return this.createAxiosResponse<MakeCallReponse>({
			AcdGroup: null,
			AcdGroupId: null,
			CallActions: null,
			CallState: null,
			CalledContact: null,
			CallerContact: null,
			Completed: null,
			Direction: null,
			Id: null,
			Outcome: null,
			StartTimeUtc: null,
			UniqueId: null
		})
	}

	public register(): Promise<AxiosResponse<RegisterResponse>> {
		return this.createAxiosResponse<RegisterResponse>({})
	}

	public search(body: SearchBody): Promise<AxiosResponse<SearchResponse>> {
		const createField = (name: string, type: string, value: any): ContactRecordField => ({
			DescriptorId: null,
			Name: name,
			Ordinal: null,
			Type: type,
			Value: value,
			WellKnownName: null
		})
		return this.createAxiosResponse<SearchResponse>([
			{
				AddinId: 'test',
				ExceptionText: null,
				Records: [
					{
						UniqueId: Math.random().toString(),
						Actions: [],
						RecordType: 'Group',
						DisplayText: '',
						Fields: [
							createField('Name', 'Text', 'Test Name'),
							createField('Company', 'Text', 'Test Company'),
							createField('Phone 1', 'Phone', '0123456789'),
							createField('Email', 'Email', 'test@company.com')
						]
					}
				]
			}
		])
	}

	public searchPeers(body: SearchBody): Promise<AxiosResponse<SearchPeersResponse>> {
		return Promise.reject('Not Implemented')
	}

	public showWindow(body: ShowWindowBody): Promise<AxiosResponse<void>> {
    return Promise.reject('Not Implemented')
	}

  public doAction(body: DoActionBody): Promise<AxiosResponse<void>> {
		return Promise.reject('Not Implemented')
	}

	public version(): Promise<AxiosResponse<VersionResponse>> {
		return this.createAxiosResponse<VersionResponse>({ ProductName: null, ProductVersion: null })
	}
}

export default MockIntegrator
