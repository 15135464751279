import { ITeamsThemeContextProps } from 'msteams-ui-components-react'
import React, { FC, useEffect, useState } from 'react'
import ContactRecord from './ContactRecord'
import LoadMore from './LoadMore'

import type { AddinCollection, IntegratorInterface } from 'Integrator/types'

import getThemeStyles from './themes'

interface AddinCollectionComponentProps {
	maxRows: number
	addinCollection: AddinCollection
	addinName: string
	integrator: IntegratorInterface
	context: ITeamsThemeContextProps['context']
	query: string
	loadMore(addinId: string): Promise<void>
}

const AddinCollectionComponent: FC<AddinCollectionComponentProps> = ({
	maxRows,
	query,
	loadMore,
	context,
	addinCollection,
	addinName,
	integrator
}) => {
	const themes = getThemeStyles(context)
	const [isHovering, setIsHovering] = useState<boolean>(false)
	const [isOpen, setIsOpen] = useState<boolean>(true)
	const [shouldLoadMore, setShouldLoadMore] = useState<boolean>(true)
	const [isLoading, setIsLoading] = useState(false)

	const loadMoreContacts = async () => {
		setIsLoading(true)
		await loadMore(addinCollection.AddinId)
		setIsLoading(false)
	}

	useEffect(() => {
		setIsOpen(true)
	}, [addinCollection])

	useEffect(() => {
		const checkShouldLoadMore = async () => {
			// quick check if returned results has a remainder of maxRows
			if (addinCollection.Records.length % maxRows !== 0) {
				setShouldLoadMore(false)
				return setIsLoading(false)
			}
			// TODO: cache returned results from following requests
			if (addinCollection.AddinId === 'Users') {
				const { data } = await integrator.searchPeers({
					query,
					offset: addinCollection.Records.length,
					count: maxRows
				})
				setShouldLoadMore(data.length > 0)
			} else {
				const { data } = await integrator.search({
					query,
					offset: addinCollection.Records.length,
					count: maxRows
				})
				setShouldLoadMore(data.find((addin) => addin.AddinId === addinCollection.AddinId).Records.length > 0)
			}
		}
		checkShouldLoadMore()
	}, [addinCollection, maxRows, integrator, query])
	return (
		<div style={themes.wrapper} className='flex flex-col'>
			<div
				onMouseEnter={() => setIsHovering(true)}
				onMouseLeave={() => setIsHovering(false)}
				style={themes.title}
				onClick={() => setIsOpen(!isOpen)}
				className='cursor-pointer p-2 flex items-center'
			>
				<div className='flex' style={{ transform: isOpen ? 'rotate(45deg)' : undefined }}>
					<svg
						style={isHovering ? { color: '#1cc2f5' } : isOpen ? themes.caretOpen : themes.caretClosed}
						className='w-2 h-2 m-2 fill-current stroke-current'
						viewBox='0 0 400 400'
					>
						<path strokeWidth={20} d='M100,00L300,200L100,400z'></path>
					</svg>
				</div>
				<span className='font-bold'>{addinName}</span>
			</div>
			{isOpen ? (
				<>
					{addinCollection.Records.map((record) => (
						<ContactRecord context={context} integrator={integrator} key={record.UniqueId} contact={record} />
					))}
					{shouldLoadMore ? <LoadMore context={context} onClick={isLoading ? undefined : loadMoreContacts} /> : null}
				</>
			) : null}
		</div>
	)
}

export default AddinCollectionComponent
